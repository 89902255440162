<div class="modal-dialog m-0 p-4 document">
    <div class="modal-header text-center border-0 justify-content-center p-0 mb-2">
        <h2 class="modal-title ml-auto fw-700 pl-5" id="exampleModalLabel">
          Logout Confirmation
        </h2>
       
        <img src="assets/images/icons/cross-icon.svg" alt="" class="position-absolute top-0 end-0 m-2 cursor" style="height: 24px;"  (click)="onClose()">
    </div>
    <div class="modal-body fs-16 fw-500 text-center p-0 mb-2">
        Do you want to Logout ?
    </div>
    <div class="modal-footer d-flex justify-content-center border-0 p-0">
        <button type="submit" class="btn btn-secondary fw-600" (click)="onClose()">Cancel</button>
        <button type="button" class="btn btn-outline btn-pill fw-600" (click)="logout()">Logout</button>
    </div>
</div>
