import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-referral-code',
  templateUrl: './referral-code.component.html',
  styleUrl: './referral-code.component.scss',
})
export class ReferralCodeComponent {
  referralCode = '';
  @Output() onReferralCode = new EventEmitter<string>();
  constructor(private bsModalRef: BsModalRef, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.referralCode = params['referral_code'] || '';
    });
  }

  onClose() {
    this.bsModalRef.hide();
  }
  sendCode() {
    if (!this.referralCode) return;
    this.onReferralCode.emit(this.referralCode);
    this.onClose();
  }
}
