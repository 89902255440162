import { Component, OnInit } from "@angular/core";
import { ToasterService } from "./toaster.service";

@Component({
    selector: 'app-toaster-container',
    template: `
    <app-toaster
        *ngFor="let toaster of toasters; let i = index"
        [toaster]="toaster"
        [top]="getTopPosition(i)"
        (close)="hideToaster($event)">
    </app-toaster>
    `,
    styles: [``]
})
export class ToasterContainerComponent implements OnInit {
    toasters: any[] = [];

    constructor(private toasterService: ToasterService) {}

    ngOnInit() {
        this.toasterService.toasters$.subscribe(toasters => {
            this.toasters = toasters;
        });
    }

    getTopPosition(index: number): string {
        return `${50 + (index * 95)}px`;
    }

    hideToaster(id: number) {
        this.toasterService.hideToaster(id);
    }
}
