import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom: any) { }
  setCanonicalURL(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    // const link: HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(link);
    // const canURL = url == undefined ? this.dom.URL : url;
    // link.setAttribute('href', canURL);
  }
}
