import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { localStorageService } from 'src/app/services/localstorage.service';
import { Email } from 'src/app/shared/regex';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public env = environment;
  public loginForm: UntypedFormGroup;
  hidePwd: boolean = false;
  
  constructor(private main: MainService, private fb: UntypedFormBuilder, private _local: localStorageService, private apiSer: ApiService, private _router: Router, private _shared: SharedService) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.maxLength(100), Validators.pattern(Email)]],
      password: ["", [Validators.required, Validators.maxLength(100)]],
    })
  }


  public login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAsDirty();
      this.loginForm.markAllAsTouched();
      return;
    }
    if (!(this.loginForm.controls['email'].value + '').trim()) {
      this._shared.customPopups('Please enter valid email', 1);
      return;
    }
    const data = {
      doctor_email: this.loginForm.controls['email'].value,
      doctor_password: this._shared.crypto(this.loginForm.controls['password'].value),
    };
    this.apiSer.api(data, 'auth/email_login').subscribe((response) => {
      if (response.is_error == 0) {
        if (response.reset_password_token) {
          this._local.customSetItem('token', response.access_token);
          this._local.customSetItem('reset_password_token', response.reset_password_token);
          this._local.customSetItem('reset_email', response.doctor_email);
          this._router.navigate(['new-password']);
          return;
        }
        this.main.checkProfile(response);
      } else {
        this.loginForm.markAsDirty();
        this.loginForm.markAsTouched();
        window.scroll(0, 0);
      }
    });
    // this.local.customSetItem('token', 'test');
    //   // this.apiSer.api()
    // this.main.auth.accessTokenLogin();
  }
}
