import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrl: './logout-modal.component.scss'
})
export class LogoutModalComponent {
  constructor(private bsModalRef: BsModalRef, private _api: ApiService) { }

  onClose() {
    this.bsModalRef.hide();
  }

  logout() {
    this.onClose();
    this._api.api({}, 'profile/logout').subscribe((resp: any) => {
      if (!resp.is_error) {
        this._api.loginRedirect();
      }
    })
  }
  
}
