import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import * as REGEX from '../../../shared/regex';
import { MainService } from 'src/app/services/main.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrl: './business-info.component.scss',
})

export class BusinessInfoComponent implements OnInit {
  industryList: any = [
    {
      ind_name: 'Others',
      ind_id: 9999
    }
  ];
  sub_industries: any = [
    {
      ind_name: 'Others',
      ind_id: 9999
    }
  ];
  practiceInfoForm: UntypedFormGroup = {} as UntypedFormGroup; // Default value
  mask = '000-000-0000';
  country_data: any;
  constructor(
    private _main: MainService,
    private formBuilder: UntypedFormBuilder,
    private apiSer: ApiService,
    private _router: Router,private _shared: SharedService
  ) { }
  ngOnInit() {
    this.getIndustryList();
    this.practiceInfoForm = this.formBuilder.group({
      practice_name: ['', [Validators.required, Validators.pattern(REGEX.Name_1), Validators.maxLength(30)]],
      practice_email: ['', [Validators.required, Validators.pattern(REGEX.Email)]],
      practice_mobile: ['', [Validators.required, Validators.pattern(REGEX.number_regex)]],
      ind_name: [''],
      ind_id: ['', [Validators.required]],
      doctor_speciality_name: [''],
      doctor_speciality: ['', [Validators.required]]
    });
    this._shared.country_currency_info$.subscribe(data => {
      if (data && data.country && data.country['country_phone_format']) {
        this.country_data = data.country;
        this.mask = data.country['country_phone_format'];
      }
    })
  }

  getIndustryList() {
    this.apiSer.api({ is_verified: 1 }, 'profile/get_industry_list', 'get').subscribe((data: any) => {
      if (data && data.is_error == 0) {
        console.log(data);
        this.industryList = data.industries;
        if (this.industryList && this.industryList.length) {
          if (this.industryList[0]['ind_id'] == 9999) {
            this.practiceInfoForm.controls['ind_name'].setValidators([Validators.required])
          }
          this.practiceInfoForm.patchValue({ ind_id: this.industryList[0]['ind_id'] });
          this.practiceInfoForm.patchValue({ ind_name: '' });
        }
        this.getSubIndustrylist();
      } else {
        this.industryList = [];
      }
    });
  }

  getSubIndustrylist() {
    const data = {
      ind_id: parseInt(this.practiceInfoForm.controls['ind_id'].value),
      is_deleted: 0,
      is_verified: 1,
      full_data: 0,
    };
    this.apiSer.api(data, 'profile/get_all_sub_industries', 'get').subscribe((data: any) => {
      if (data && data.is_error == 0) {
        if (data.paignated_result && data.paignated_result.length) {
          if (data.paignated_result[0]['doctor_speciality'] == 9999) {
            this.practiceInfoForm.controls['doctor_speciality_name'].setValidators([Validators.required])
          }
          this.practiceInfoForm.patchValue({ 'doctor_speciality': data.paignated_result[0].doctor_speciality });
          this.practiceInfoForm.patchValue({ 'doctor_speciality_name': '' });
        }
        this.sub_industries = data.paignated_result;
        this.practiceInfoForm.updateValueAndValidity();
      } else {
        this.sub_industries = [];
      }
    });
  }

  submitPracticeInfo() {
    if (this.practiceInfoForm.invalid) {
      this.practiceInfoForm.markAsDirty();
      this.practiceInfoForm.markAllAsTouched();
      return;
    }

    const data = {
      practice_name: this.practiceInfoForm.controls['practice_name'].value,
      practice_email: this.practiceInfoForm.controls['practice_email'].value,
      practice_mobile: this.practiceInfoForm.controls['practice_mobile'].value + '',
      practice_mobile_country_code: '+1',//this.practiceInfoForm.controls['practice_mobile_country_code'].value,
      ind_id: this.practiceInfoForm.controls['ind_id'].value,
      sub_ind_id: this.practiceInfoForm.controls['doctor_speciality'].value,
      ind_name: this.practiceInfoForm.controls['ind_name'].value,
      sub_ind_name: this.practiceInfoForm.controls['doctor_speciality_name'].value,
    }
    this.apiSer.api(data, 'profile/business_setting').subscribe((data) => {
      if (data.is_error == 0) {
        this._router.navigate(['app', 'dashboard']);
        this._main.auth.accessTokenLogin();
      } else {
        this.practiceInfoForm.markAsDirty();
        this.practiceInfoForm.markAsTouched();
        window.scroll(0, 0);
      }
    });
  }
}
