import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  currentYear: number;
  selectedMonth: Date;
  selectedDate: Date;
  months: string[] = [];
  days: Date[] = [];
  daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  constructor() {
    const today = new Date();
    this.selectedDate = new Date();
    this.currentYear = today.getFullYear();
    this.selectedMonth = today;
    this.months = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  }

  ngOnInit(): void {
    this.generateCalendar(this.selectedMonth);
  }

  generateCalendar(month: Date): void {
    this.days = [];
    const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);

    // Days before the start of the month
    const firstDay = startOfMonth.getDay();
    for (let i = 0; i < firstDay; i++) {
      this.days.push(new Date(month.getFullYear(), month.getMonth(), -i));
    }

    // Days of the current month
    for (let day = 1; day <= endOfMonth.getDate(); day++) {
      this.days.push(new Date(month.getFullYear(), month.getMonth(), day));
    }

    // Days after the end of the month
    const lastDay = endOfMonth.getDay();
    for (let i = lastDay + 1; i < 7; i++) {
      this.days.push(new Date(month.getFullYear(), month.getMonth() + 1, i - lastDay));
    }
  }

  selectMonth(index: number): void {
    this.selectedMonth = new Date(this.currentYear, index, 1);
    this.generateCalendar(this.selectedMonth);
  }

  selectDate(day: Date): void {
    if (day.getMonth() === this.selectedMonth.getMonth()){
      this.selectedDate = day;
    }
  }

  isSelected(day: Date): boolean {
    return this.selectedDate 
           && this.selectedDate.getFullYear() === day.getFullYear() 
           && this.selectedDate.getMonth() === day.getMonth() 
           && this.selectedDate.getDate() === day.getDate();
  }

  // Navigation methods
  previousYear(): void {
    this.currentYear--;
    if (this.selectedMonth.getFullYear() < this.currentYear) {
      this.selectedMonth = new Date(this.currentYear, 0, 1);
      this.generateCalendar(this.selectedMonth);
    }
  }

  nextYear(): void {
    this.currentYear++;
    if (this.selectedMonth.getFullYear() > this.currentYear + 1) {
      this.selectedMonth = new Date(this.currentYear + 1, 0, 1);
      this.generateCalendar(this.selectedMonth);
    }
  }
}