<div class="position-absolute" style="top: 25px;right: 25px;">
    <div class="btn-group w-100" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="btn btn-outline align-items-center d-flex justify-content-between py-2"
            aria-controls="dropdown-basic">
            <span class="d-flex align-items-center">
                <img src="assets/images/icons/multilanguage-icon.png" alt="" class="me-2">
                {{ selectedLanguage && selectedLanguage.lan_name }}
            </span>
            <i class="fa fa-angle-down fs-20 ms-2"></i>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem" (click)="updatePanelLanguage(item)" *ngFor="let item of language_data">
                <span class="dropdown-item">{{ item.lan_name }}</span>
            </li>
        </ul>
    </div>
</div>