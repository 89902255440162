import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { localStorageService } from 'src/app/services/localstorage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LogoutModalComponent } from 'src/app/shared/logout-modal/logout-modal.component';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrl: './layout-header.component.scss'
})
export class LayoutHeaderComponent {
  settingsList = [
    {
      label: 'Profile Settings',
      icon: 'assets/images/icons/user-icon.svg',
      path: 'app/dashboard'
    },
    {
      label: 'Financial Settings',
      icon: 'assets/images/icons/users-icon.svg',
      path: 'app/dashboard'
    },
    {
      label: 'Manage Users',
      icon: 'assets/images/icons/file-icon.svg',
      path: 'app/dashboard'
    },
    {
      label: 'Support',
      icon: 'assets/images/icons/support-icon.svg',
      path: 'app/dashboard'
    }
  ];
  userProfile: any;
  bsModalRef: BsModalRef | undefined;
  showLogout = false;
  search_string = '';

  constructor(private modalService: BsModalService, private _local: localStorageService, private _router: Router, private _shared: SharedService
  ) {
    this.userProfile = this._local.customGetItem('userProfileData');
  }

  ngOnInit(): void { }

  openNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(0%)';
    }
  }

  logoutModal() {
    const initialState = {
    };
    this.bsModalRef = this.modalService.show(LogoutModalComponent, {
      initialState,
      class: 'modal-dialog-centered'
    });
  }

  showLogoutModal() {
    this.showLogout = true;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(event: Element): void {
    if (!event.classList.contains('lhc') && !event.classList.contains('logout-wrapper') && !event.classList.contains('profile-icon')) {
      this.showLogout = false;
    }
  }

  userAction(action: any) {
    this._router.navigate([action.path]);
  }

  emitSeacrh() {
    this._shared.search_emitter.next(this.search_string);
  }
}
