import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  private hideSideMenuUrls: string[] = ['payment-plans', 'finance-customer',];
  private hideMainHeaderUrls: string[] = ['payment-plans', 'finance-customer', 'proposals/details', 'contracts/details'];
  showSideMenu: boolean = true;
  showMainHeader: boolean = true;
  constructor(private _router: Router) { }


  ngOnInit(): void {
    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.updateUI(event.url);
      }
    });

    // Check the initial URL on component initialization
    this.updateUI(this._router.url);
  }

  private updateUI(url: string): void {

    this.showSideMenu = !this.hideSideMenuUrls.some(route => url.includes(route));


    this.showMainHeader = !this.hideMainHeaderUrls.some(route => url.includes(route));
  }
}
