<div class="login-main-wrapper">
  <div class="inner-wrapper d-flex flex-column flex-lg-row">
    <div class="">
      <app-left-layout></app-left-layout>
    </div>
    <div
      class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto">
      <div class="right-content  p-3 p-lg-0">
        <div class="mb-4">
          <h2 class="fs-36 fw-600 m-0 mb-3">
            Unlock Your <br> Financial World
          </h2>
          <p class="fs-16 fw-400 text-secondary m-0">
            Welcome back! Please enter your details.
          </p>
        </div>

        <form (submit)="submit()" [formGroup]="forgotPasswordForm" class="col-12">

          <div class="col-12 form-group mt-4 mb-3">
            <div
              [ngClass]="[ (forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
              class="">
              <label for="text" class="label-name fs-14 w-100">
                Email
              </label>
              <input type="text" class="input_field w-100" name="email" required formControlName="email"
                placeholder="Enter your Email" />
            </div>
            <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
              *ngIf="(forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].invalid)">
              {{forgotPasswordForm.controls['email'].errors ? 'Please enter a valid Email/Phone' : ''}} </p>
          </div>
          <div class="col-12  mt-2 w-100 text-end">
            <p>
              <a routerLink="/login" class="fw-600 cursor  d-inline-block d-blue fs-14">Back to Login</a>
            </p>
          </div>

          <div class="col-12 mt-4">
            <button autofocus button-primary-auth type="submit"
              class="w-100 border-0 mb-3 btn btn-secondary fs-16 fw-600">Submit</button>
          </div>
        </form>
        <p class="fs-14 fw-400 m-0 text-center">
          Don’t have an account?
          <span class="fw-500 link-text cursor" [routerLink]="'/register'">Sign up</span>
        </p>
      </div>
    </div>
  </div>
</div>