import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { localStorageService } from './localstorage.service';
// import { MainService } from '../main/main.service';

@Injectable({
    providedIn: 'root'
})

export class SocketsService {
    private socket!: Socket;
    constructor(private _shared: SharedService, private _local: localStorageService) {
        this.socket = io(environment.socketBaseUrl, {
            path: '/api/socket.io',
            transports: ['websocket']
        });
    }

    connect() {
        console.log('socket.connected', this.socket.connected);
        this.socket.connect();
        this.socket.on('connect', () => {
            console.log('Socket connected:', this.socket.id);
        });
        this.socket.on('disconnect', () => {
            console.log('Socket disconnect:', this.socket.id);
            this.socket.connect();
        });
        
        this.socket.on('connect_error', (error) => {
            console.error('Connection failed:', error.message);
        });

        let userData = this._local.customGetItem('userProfileData');
        if (typeof userData == 'string') {
            userData = JSON.parse(userData);
        }
        if (userData.doctor_id) {
            this.emit(this._shared.socketIds.DOCTOR_CONNECTED, {doctor_id: userData.doctor_id});
        }
    }

    // Emit events to the server
    emit(eventName: string, data: any): void {
        this.socket.emit(eventName, data);
    }

    // Listen for events from the server
    on(eventName: string, data: any) {
        this.socket.on(eventName, data);
    }

    // Set off events from the server
    off(eventName: string, ) {
        this.socket.off(eventName);
    }

    disconnect(): void {
        if (this.socket) {
          this.socket.disconnect();
        }
    }
}