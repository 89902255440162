<div class="calender-wrapper d-flex align-items-center mxw-850 h-100 mx-auto">
  <div class="row">
    <div class="col-md-6 border-end">
      <div class="month-list p-4 text-center">
        <div class="d-flex justify-content-between mb-3">
       
          <img src="assets/images/icons/back-arrow-circle.svg" alt="" class="cursor hw-32" (click)="previousYear()">
      
          <p class="current-date fs-18 m-0 mb-3 fw-700 mb-0 mx-2 ">
            {{ selectedDate.getDate() }} {{ selectedMonth | date: 'MMMM yyyy' }}
          </p>
         
           <img src="assets/images/icons/back-arrow-circle.svg" alt="" class="cursor hw-32" (click)="nextYear()" style="transform: rotate(180deg);">
         
        </div>
        <ul class="row decoration-none p-0">
          <li class="col-4 fs-14 mb-3 cursor" *ngFor="let month of months; let i = index" (click)="selectMonth(i)"  [ngClass]="{'text-white bg-purple rounded-pill': selectedMonth.getMonth() === i}">
            <p class="fs-14 fw-600 p-2 m-0">
              {{ month.slice(0, 3) }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-6">
      <div class="calendar-days px-4">
        <div *ngFor="let day of daysOfWeek" class="day-name fs-14 fw-400 d-flex align-items-center justify-content-center p-2 border-0 mb-2 hw-50">{{ day }}</div>
      </div>
      <div class="calendar-grid px-4">
        <div *ngFor="let day of days" [ngClass]="{'day-disabled': day.getMonth() !== selectedMonth.getMonth(),'text-white bg-purple rounded-circle': isSelected(day)}"
           class="calendar-day d-flex align-items-center justify-content-center p-2 border-0 mb-2 hw-50 cursor"
          (click)="selectDate(day)">
          {{ day.getDate() }}
        </div>
      </div>
    </div>
  </div>
</div>