import { Injectable } from "@angular/core";

@Injectable()
export class localStorageService {
    // get specific storage items
  customGetItem(key: string) {
    let customer: any = localStorage.getItem("finance_mutual_storage");
    if (customer) {
      customer = JSON.parse(customer);
    }
    if (!customer) return null
    return customer[key] || null
  }

  // set specific storage items
  customSetItem(key: string, value: any) {
    let customer: any = localStorage.getItem("finance_mutual_storage");
    if (customer) {
      customer = JSON.parse(customer);
    }
    if (!customer) {
      localStorage.setItem("finance_mutual_storage", JSON.stringify({ [key]: value }))
    }
    if (customer) {
      customer[key] = value;
      localStorage.setItem("finance_mutual_storage", JSON.stringify(customer))
    }
  }

  // clear specific storage item
  customRemoveItem(key: string) {
    let customer: any = localStorage.getItem("finance_mutual_storage");
    if (customer) {
      customer = JSON.parse(customer);
    }
    if (customer) {
      delete customer[key];
      localStorage.setItem("finance_mutual_storage", JSON.stringify(customer));
    }
  }

  // clear all storage items
  customClearAllItems() {
    let keys_to_not_delete = ['user', 'client_version']; // keys in localstorage that you want to keep after logout
    let customer: any = localStorage.getItem("finance_mutual_storage");
    if (customer) {
      let localstorage_keys = Object.keys(JSON.parse(customer));
      localstorage_keys.forEach(key => {
        this.customRemoveItem(key)
      });
    }
  }

   // get specific cookie
   getCookie(name: string) {
    const cookie: any = {};
    // tslint:disable-next-line:only-arrow-functions
    document.cookie.split(';').forEach(function(el) {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  // delete cookie
  deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}