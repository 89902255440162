<div class="login-main-wrapper">
  <div class="inner-wrapper d-flex flex-column flex-lg-row">
    <div class="">
      <app-left-layout></app-left-layout>
    </div>
    <div
      class="login-right-side flex-fill d-flex flex-column justify-content-center align-items-center vh-100 overflow-lg-auto">
      <div class="right-content  p-3 p-lg-0">
        <div class="row justify-content-center">
          <div class="m-w-520 py-md-5 col-12">
            <div class="row py-md-5 py-4 flex-wrap">

              <div class="col-12">
                <h1 class="fs-36 fw-600 m-0 mb-3">
                  {{'CREATE NEW' | titlecase}}<span style="color:var(--d-blue)"> {{'PASSWORD!' | titlecase}}</span>
                </h1>
              </div>

              <form class="m-w-520 w-100 " [formGroup]="newPasswordForm" (submit)="submitForm()">

                <div class="col-12 form-group mt-4 mb-3 ">
                  <div
                    [ngClass]="[newPasswordForm.controls['newPassword'].touched && newPasswordForm.controls['newPassword'].invalid  ? '  b-10 m-h-56 custom-form inputContainer' : ' b-10 m-h-56 custom-form']"
                    class=" position-relative">
                    <label for="text" class="label-name fs-14 w-100">
                      New Password
                    </label>
                    <div class="position-relative">
                      <input [type]="hideNewPassword == true ? 'password' : 'text'" class="input_field w-100"
                        name="password" formControlName="newPassword" autocomplete="off" required maxlength="75"
                        placeholder="Enter New Password" />
                      <img
                        [src]="hideNewPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                        alt="" class="position-absolute eye-icon cursor" (click)="hideNewPassword = !hideNewPassword">
                    </div>
                  </div>

                  <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                    *ngIf="newPasswordForm.controls['newPassword'].touched && newPasswordForm.controls['newPassword'].invalid && !newPasswordForm.controls['newPassword'].dirty">
                    Please valid enter password.
                  </p>
                </div>



                <div class="col-12 form-group mt-4 mb-3 ">
                  <div
                    [ngClass]="[(newPasswordForm.controls['confirmPassword'].touched && newPasswordForm.controls['confirmPassword'].invalid) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'] && newPasswordForm.controls['confirmPassword'].touched ) ? '  b-10 m-h-56 custom-form inputContainer' : ' b-10 m-h-56 custom-form']"
                    class=" position-relative">
                    <label for="text" class="label-name fs-14 w-100">
                      Confirm Password
                    </label>
                    <div class="position-relative">
                      <input [type]="hideConfirmPassword == true ? 'password' : 'text'" class="input_field w-100"
                        name="password" formControlName="confirmPassword" autocomplete="off" required maxlength="75"
                        placeholder="Confirm Your Password" />
                      <img
                        [src]="hideConfirmPassword ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'"
                        alt="" class="position-absolute eye-icon cursor"
                        (click)="hideConfirmPassword = !hideConfirmPassword">
                    </div>
                  </div>

                  <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                    *ngIf="(newPasswordForm.controls['confirmPassword'].touched && newPasswordForm.controls['confirmPassword'].invalid && !newPasswordForm.controls['confirmPassword'].dirty) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'] && newPasswordForm.controls['confirmPassword'].touched )">
                    {{ newPasswordForm.errors && newPasswordForm.errors['noMatch'] &&
                    newPasswordForm.value['confirmPassword'].length > 0 ? "Passwords did not match." : 'Please enter a
                    valid password.'}}
                  </p>
                </div>

                <div class="col-12  mt-2 w-100 text-end">
                  <p>
                    <a routerLink="/login" class="fw-600 cursor  d-inline-block d-blue fs-14">Back to Login</a>
                  </p>
                </div>

                <div class="col-12 mt-4 form-group">
                  <button button-primary-auth type="submit" class="w-100 border-0 mb-3 btn btn-secondary fs-16 fw-600 "
                    [disabled]="(newPasswordForm.invalid) || (newPasswordForm.errors && newPasswordForm.errors['noMatch'])">Change
                    Password</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>