import { Component } from '@angular/core';

@Component({
  selector: 'app-left-layout',
  templateUrl: './left-layout.component.html',
  styleUrl: './left-layout.component.scss'
})
export class LeftLayoutComponent {

}
