import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LoaderService } from '../shared/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  timer = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private _loader: LoaderService
  ) { }

  async getCaptchaToken(): Promise<any> {
    await this.timer(2000);
    return new Promise((resolve, reject) => {

      this._loader.showLoader();
      this.recaptchaV3Service.execute('action').subscribe({
        next: (token: string) => {
          this._loader.hideLoader();
          if (token) {
            resolve(token);
          } else {
            reject(null);
          }
        },
        error: (error) => {
          this._loader.hideLoader();
          console.error('ng-recaptcha execute error:', error);
          reject(null);
        }
      });
    });
  }
}
