<div class="modal-dialog m-0 p-3 p-md-5">
  <div class="modal-header  border-0 p-0 mb-2">
    <img src="assets/images/icons/referral-image.svg" alt="" class="mb-3 reffer-image">

    <img src="assets/images/icons/cross-icon.svg" alt="" class="position-absolute top-0 end-0 m-2 cursor"
      style="height: 24px;" (click)="onClose()">
  </div>
  <div class="modal-body fs-16 fw-500 p-0 mb-2">
    <h4 class="fs-36 fw-700">
      Referral Code
    </h4>
    <p class="fs-18 fw-400 ">
      Enter the referral code that your partner has shared with you.
    </p>
    <div class=" form-group mt-4 mb-3">
      <input type="text" class="input_field w-100" placeholder="Enter Referral Code here " autocomplete="off"
        [(ngModel)]="referralCode" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center border-0 p-0"
    [ngStyle]="{'cursor': !referralCode ? 'no-drop' : 'Pointer'}" (click)="sendCode()">
    <button class="btn btn-secondary w-100" [disabled]="!referralCode">
      Apply
    </button>
  </div>
</div>