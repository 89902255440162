import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { dobData, dobMask, SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-date-of-birth',
  templateUrl: './date-of-birth.component.html'
})
export class DateOfBirthComponent implements OnInit {

  dateForm: FormGroup;
  dateOfBirthMask!: dobMask;
  @Input() dobData!: dobData;
  @Input() dobSubscription = new Subject();
  @Output() submittedData: EventEmitter<any> = new EventEmitter();
  error: boolean = false;
  
  constructor(private fb: FormBuilder, private _shared: SharedService) {
    this.dateForm = this.fb.group({
      month: [(this.dobData && this.dobData.month) || '', [Validators.required, Validators.pattern('^\\d{1,2}$')]],
      day: [(this.dobData && this.dobData.day) || '', [Validators.required, Validators.pattern('^\\d{1,2}$')]],
      year: [(this.dobData && this.dobData.year) || '', [Validators.required, Validators.pattern('^\\d{4}$')]]
    });
  }

  ngOnInit(): void {
    this._shared.country_currency_info$.subscribe(data => {
      if (data && data.country) {
        this.dateOfBirthMask = this._shared.dateFormating[data.country['country_code']];
      }
    })

    if (this.dobData &&  (this.dobData.month || this.dobData.day || this.dobData.year)) {
      this.dateForm.patchValue({
        month: this.dobData.month,
        day: this.dobData.day,
        year: this.dobData.year
      })
    }

    this.dobSubscription.subscribe((data: any) => {
      if (data){
        this.error = true;
      } else {
        this.error = false;
      }
    })
  }

  // Handle date input changes
  onDateInput(): void {
    this.error = false;
    const month = this.dateForm.controls['month']?.value + '';
    const day = this.dateForm.controls['day']?.value + '';
    const year = this.dateForm.controls['year']?.value + '';

    // Validate and restrict the month input
    if (month && month.length >= 2) { // Only validate after two digits are entered
      let monthNum = parseInt(month, 10);
      if (monthNum < 1) {
        this.dateForm.patchValue({ month: '01' }, { emitEvent: false });
      } else if (monthNum > 12) {
        this.dateForm.patchValue({ month: '12' }, { emitEvent: false });
      }
    } else if (month.length === 1 && (parseInt(month, 10) > 2 && parseInt(month, 10) < 10)){
      this.dateForm.patchValue({ month: '0' + month }, { emitEvent: false });
    }

    // Validate and restrict the day input based on the month and year
    if (day && day.length === 2 && month && year) { // Only validate after two digits
      const maxDays = this.getDaysInMonth(parseInt(month, 10), parseInt(year, 10));
      let dayNum = parseInt(day, 10);

      if (dayNum < 1) {
        this.dateForm.patchValue({ day: '01' }, { emitEvent: false });
      } else if (dayNum > maxDays) {
        this.dateForm.patchValue({ day: maxDays.toString().padStart(2, '0') }, { emitEvent: false });
      }
    } else if (day && parseInt(day, 10) > 31) {
      this.dateForm.patchValue({ day: '01' }, { emitEvent: false });
    }

    // Validate and restrict the year input
    if (year && year.length >= 4) { // Only validate after the full year is entered
      let yearNum = parseInt(year, 10);
      const currentYear = new Date().getFullYear();

      if (yearNum < 1900) {
        this.dateForm.patchValue({ year: '1900' }, { emitEvent: false });
      } else if (yearNum > currentYear) {
        this.dateForm.patchValue({ year: currentYear.toString() }, { emitEvent: false });
      }
    }

    this.submittedData.emit(this.dateForm.value);
  }

  // Get number of days in a month
  getDaysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }
}