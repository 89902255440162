import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { localStorageService } from './localstorage.service';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  cache_data: any;

  constructor(
    private _local: localStorageService, private _shared: SharedService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (!this._local.customGetItem('user')) {
      this._shared.device_id();
    }
    const currentLanguage = this.gt_get_cookie('googtrans').split('/').pop();
    const headers = new HttpHeaders({
      device_type: environment.device_type,
      app_type: environment.app_type,
      app_version: environment.app_version,
      device_token: environment.device_token,
      device_id: this._local.customGetItem('user'),
      environment: environment.environment,
      Authorization: this._local.customGetItem('token') || ''
    })
    req = req.clone({ headers });
    if (req.method.toLowerCase() === 'post') {
      req = req.clone({ headers: req.headers.set('x-client-url', window.location.href) });
    }
    const timezone = this._local.customGetItem('timezone');
    if (this._local.customGetItem('userProfileData')) {
      const userProfile = this._local.customGetItem('userProfileData');
      const clientVersion = this._local.customGetItem('client_version');
      if (userProfile && userProfile['doctor_id']) {
        req = req.clone({
          headers: req.headers.append('x-doctor-id', userProfile['doctor_id'] ? userProfile['doctor_id'] + '' : '')
        });
      }
      if (clientVersion) {
        req = req.clone({
          headers: req.headers.append('x-client-version', clientVersion)
        });
      }
    }

    req = req.clone({
      headers: req.headers.append('x-lan-code', currentLanguage || 'en')
    });

    req = req.clone({
      headers: req.headers.append('timezone', timezone)
    });

    return next.handle(req)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const data = event.body;
          }
        }),
        catchError((error: any) => {
          return throwError(() => error)
        })
      );
  }

  private gt_get_cookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}

