import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface Toaster {
    id: number;
    heading: string;
    description: string;
    type: string;
}

@Injectable()
export class ToasterService {
    private toastersSubject = new BehaviorSubject<Toaster[]>([]);
    private nextId = 0;
    private maxToasters = 5;
    public toasters$ = this.toastersSubject.asObservable();

    showToaster(heading: string, description: string, type: string) {
        const currentToasters = this.toastersSubject.value;

        if (currentToasters.length >= this.maxToasters) {
            currentToasters.shift();
        }

        const newToaster: Toaster = {
            id: this.nextId++,
            heading: heading,
            description: description,
            type: type
        };

        this.toastersSubject.next([...this.toastersSubject.value, newToaster]);
    }

    hideToaster(id: number) {
        this.toastersSubject.next(this.toastersSubject.value.filter(toaster => toaster.id !== id));
    }

    hideAllToasters() {
        this.toastersSubject.next([]);
    }
}
