import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleAuthService {
    private googleLoaded: boolean = false;
    private client: any;
    private env = environment;
    constructor() {
        this.loadGoogleScript();
    }

    private loadGoogleScript() {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            this.googleLoaded = true;
        };
        document.head.appendChild(script);
    }

    public async initClient(clientId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.googleLoaded) {
                    this.client = google.accounts.oauth2.initTokenClient({
                        client_id: this.env.google_client_id,
                        scope: 'email profile openid',
                        callback: '',
                        use_fedcm_for_prompt: true
                    });
                    clearInterval(interval);
                    resolve();
                }
            }, 100);

            setTimeout(() => {
                clearInterval(interval);
                reject('Google API script load timeout');
            }, 10000); // 10 seconds timeout
        });
    }

    public getClient() {
        return this.client;
    }

    public isGoogleLoaded() {
        return this.googleLoaded;
    }

    // initialise google accounts
    googelSignIn() {
        this.client = google.accounts.id.initialize({
            client_id: this.env.google_client_id
        });
    }
}