import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'app-toaster',
    template: `
    <div class="toaster-wrapper p-3 position-fixed" [id]="toaster.id" [ngClass]="{success: toaster.type === 'success', error: toaster.type === 'error', warning: toaster.type === 'warning' }" [ngStyle]="{'top': top}">
        <img src="assets/images/icons/cross-icon.svg" alt="" class="position-absolute close-icon fs-14 cursor" (click)="closeToaster()">
        <h1 class="m-0 fs-24">{{ toaster.heading }}</h1>
        <p class="m-0 fs-14">{{ toaster.description }}</p>
    </div>
    `,
   
})
export class ToasterComponent {
    @Input() toaster: any;
    @Input() top: string = '0px';
    @Output() close = new EventEmitter<number>();

    closeToaster() {
        this.close.emit(this.toaster.id);
    }
}
