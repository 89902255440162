<div class="px-3 pt-3 position-relative" [style.min-height.px]="stripeData.minHeight" style="padding-bottom: 80px;">
    <div class="d-flex justify-content-between fs-12 fw-600">
        <label class="fs-18 fw-700 mb-2">Payment Method</label>
        <img src="assets/images/icons/cross-icon.svg" alt="" class="hw-24 p-1 cursor rounded-circle border" (click)="modalHide()">
    </div>
    
    <!-- card/bank element -->
    <div style="border: none;padding: 0;" class="-stripe-card" id="payment-element"></div>
    
    <!-- wallets option if enabled -->
    <div id="payment_request_button"></div>
    
    <!-- Address/Billing Element -->
    <div *ngIf="!bankType" class="col-md-12">
        <div class="mt-3">
            <label class="fs-18 fw-700 mb-2">Billing details</label>
        </div>
        <div id="address-element"></div>
    </div>
    
    <div (click)="confirmPaymentSetUp()" class="d-flex align-item-end position-absolute bottom-0 start-0 w-100 hw-80 p-3">
        <button button-primary-auth class="px-5 w-100  btn btn-secondary">Add</button>
    </div>
</div>