import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { localStorageService } from './localstorage.service';
import { ApiService } from './api.service';
import { SharedService } from './shared.service';
import { SocketsService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  headers: any = new HttpHeaders({ 'Content-Type': 'application/json' });
  public api_doc_json_link: any;
  public userHash: any;
  selected_language$: BehaviorSubject<any> = new BehaviorSubject({});
  openDialogTerms: BehaviorSubject<any> = new BehaviorSubject({});
  public path_redirect_to: string | undefined;
  authenticateApiResponse$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private router: Router,
    private _local: localStorageService,
    private apiSer: ApiService,
    private _shared: SharedService,
    public _socket: SocketsService
  ) { }

  // access token login api to get all data 
  accessTokenLogin() {
    this.apiSer.api({}, 'auth/access_token_login')
      .subscribe((response: any) => {
        if (response.flag === 116) {
          this._local.customSetItem('reset_password_token', response.reset_password_token);
          this.router.navigate(['new-password']);
        } else if (!response.is_error) {
          if (response['redirectUrl'] && response['redirectUrl'].url) {
            this.router.navigate([response['redirectUrl'].url], { queryParams: response['redirectUrl'].query });
          } else {
            // if user has just registered but not completed all steps then first check mobile validation then industry validation
            if (response.mobile_required) {
              this.router.navigate(['verify-login']);
              return;
            } else if (response.ind_id_required) {
              this.router.navigate(['business-info']);
              return;
            } else {
              if (response.country_currency_info) {
                this._shared.country_currency_info$.next(response.country_currency_info);
              }
              // proceed to dashboard
              if (response.doctor_profile) {
                this._local.customSetItem('userProfileData', response.doctor_profile);
              }
              if (response.menu && response.menu.length > 0 && response.menu.filter((menu: any) => menu.path.includes('dashboard')).length == 0) {
                // this.is_from_login = true;
                this.router.navigate(['app', 'finance-customer']);
              } else {
                if (window.location.pathname.includes('business-info') || window.location.pathname.includes('verify-login')) {
                  this.router.navigate(['app', 'dashboard']);
                }
              }
              this._socket.connect()
            }
          }
          if (response.user_hash) {
            this.userHash = response.user_hash;
          }
          this.authenticateApiResponse$.next(response);
        }
      });
  }

}

