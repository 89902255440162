import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MainService } from './services/main.service';
import { localStorageService } from './services/localstorage.service';

export const authGuard: CanActivateFn = async (route, state) => {
    const authService = inject(AuthService);
    const _local = inject(localStorageService);
    const _main = inject(MainService);
    const router = inject(Router);
    if (await isAuthenticated()) {
        // authService.dollarSign$.subscribe(resp => {
        //     _main.document_label = (resp && resp.country && resp.country['identity_label']) || '';
        //     _main.minDocumentIdLength = (resp && resp.country && resp.country['identity_document_min_length']) || '';
        //     _main.maxDocumentIdLength = (resp && resp.country && resp.country['identity_document_max_length']) || '';
        // });
        return true;
    } else {
        if (authService.path_redirect_to && authService.path_redirect_to.includes('website')) {
            router.navigate(['/register'], { queryParamsHandling: 'merge' });
            return false;
        } else {
            _local.customClearAllItems();
            router.navigate(['/login'], { queryParamsHandling: 'merge' });
            return false;
        }
    }
};

async function isAuthenticated() {
    const localService = new localStorageService();
    if (!localService.customGetItem('token') || localService.customGetItem('token') == null || localService.customGetItem('reset_password_token')) {
        return false;
    } else {
        return true;
    }
}