import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { MainService } from 'src/app/services/main.service';
import { localStorageService } from 'src/app/services/localstorage.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit {

  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  newPasswordForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private router: Router, private mainService: MainService,
    private _local: localStorageService, private apiSer: ApiService, private _shared: SharedService) {
    this.newPasswordForm = this.fb.group({
      newPassword: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    }, { validator: this.mainService.matchPassword });
  }

  ngOnInit(): void {
    if (!this._local.customGetItem('reset_password_token')) {
      this.mainService.auth.accessTokenLogin();
    }
  }

  submitForm(): void {
    if (this.newPasswordForm.value.newPassword != this.newPasswordForm.value.confirmPassword) {
      this._shared.customPopups('Passwords did not match', 2);
      return;
    }

    if (this.newPasswordForm.value.newPassword.length < 6) {
      this._shared.customPopups('Passwords must contain at least 6 characters', 2);
      return;
    }

    if (!this.newPasswordForm.value.newPassword || this.newPasswordForm.value.newPassword.trim().length == 0) {
      this._shared.customPopups('Enter a valid password', 2);
      return;
    }

    let params = {
      reset_password_token: this._local.customGetItem('reset_password_token'),
      doctor_email: this._local.customGetItem('reset_email').replace(/\s/g, '').toLowerCase(),
      doctor_password: this._shared.crypto(this.newPasswordForm.value.newPassword),
      access_token: this._local.customGetItem('token')
    };

    this.apiSer.api(params, "auth/change_password").subscribe((data: any) => {
      if (data.is_error == 0) {
        this._local.customSetItem('token', data.access_token);
        this._local.customRemoveItem('reset_password_token');
        this._local.customGetItem('reset_email');

        /*setting side menu once user logged in and got side menu data*/
        if (data.menu) {
          this.mainService.checkProfile(data);
        } else {
          this._shared.customPopups('We got some critical issues while login. Please contact Administrator.', 1);
        }
        this.mainService.checkProfile(data);
      } else {
        this._shared.customPopups('We got some critical issues while login. Please contact Administrator.', 1);
      }
      return true;
    })
  }

  routeBack() {
    this._local.customRemoveItem('token')
    this.router.navigate(["/login"]);
  }
}
