import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
// import { localStorageService } from 'src/app/services/shared.service';
import * as REGEX from '../../../shared/regex';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { CaptchaService } from 'src/app/services/captcha.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';

@Component({
  selector: 'app-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrl: './verify-phone-number.component.scss',
})
export class VerifyPhoneNumberComponent {
  step = {
    MOBILE_NUMBER: 'MOBILE_NUMBER',
    VERIFY_OTP: 'VERIFY_OTP',
  };
  selectedStep = this.step.MOBILE_NUMBER;
  verifyForm: UntypedFormGroup;
  otpForm: UntypedFormGroup;
  // localStorageSer: any;
  mask = '000-000-0000';
  otpValueGroup = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
  otp_id = 0;
  countries = [];
  country_data: any;
  last4Number: string = '';
  constructor(
    private fb: UntypedFormBuilder,
    private apiSer: ApiService,
    private router: Router,
    private cs: CaptchaService,
    private _shared: SharedService, // temporary need to hide
    private _loader: LoaderService
  ) {
    this.verifyForm = this.fb.group({
      doctor_mobile: ['', [Validators.required, Validators.pattern(REGEX.number_regex), Validators.minLength(10), Validators.maxLength(15),
      ],
      ],
    });
    this.otpForm = this.fb.group({
      first: ['', [Validators.required, Validators.maxLength(1)]],
      second: ['', [Validators.required, Validators.maxLength(1)]],
      third: ['', [Validators.required, Validators.maxLength(1)]],
      fourth: ['', [Validators.required, Validators.maxLength(1)]],
      fifth: ['', [Validators.required, Validators.maxLength(1)]],
      sixth: ['', [Validators.required, Validators.maxLength(1)]],
    });
    this._shared.country_data$.subscribe(countries => {
      this.countries = countries;
    })
    this._shared.country_currency_info$.subscribe(data => {
      if (data && data.country) {
        this.country_data = data.country;
      }
    });
  }

  async verifyPhoneNumber() {
    if (this.verifyForm.invalid) {
      this.verifyForm.markAllAsTouched();
      this.verifyForm.markAsDirty();
      return;
    }
    try {
      let data;
      this._loader.showLoader();
      const mobileNumber = this.verifyForm.controls['doctor_mobile'].value + '';
      this.last4Number = mobileNumber.substring(mobileNumber.length - 4, mobileNumber.length);
      const captcha = await this.cs.getCaptchaToken();
      // console.log('Captcha token:', token);
      data = {
        doctor_mobile: mobileNumber,
        captcha,
        doctor_mobile_country_code: this.countries[0]['mobile_code']
      };
      this.apiSer.api(data, 'profile/send_otp').subscribe((data) => {
        if (data.is_error == 0) {
          if (data.otp_id) {
            this.otp_id = data.otp_id;
          }
          this.selectedStep = this.step.VERIFY_OTP;
          this._shared.customPopups(data.otp, 0);
        } else {
          this.verifyForm.markAsDirty();
          this.verifyForm.markAsTouched();
          window.scroll(0, 0);
        }
      });
    } catch (error) {
      console.error('Failed to get captcha token', error);
      return;
    }
    // console.log(data);
  }

  onInput(event: any, currentIndex: number): void {
    const inputValue = event.target.value;
    if (inputValue && (currentIndex < (this.otpValueGroup.length - 1))) {
      const nextInput = this.otpValueGroup[currentIndex + 1];
      this.setFocus(nextInput);
    }
  }

  onDelete(event: any, currentIndex: number): void {
    if (!event.target.value && currentIndex > 0) {
      const previousInput = this.otpValueGroup[currentIndex - 1];
      this.setFocus(previousInput);
    }
  }

  setFocus(input: string) {
    if (input) {
      document.getElementById(input)?.focus();
    }
  }

  verifyOtp() {
    if (this.otpForm.invalid) {
      this.otpForm.markAsDirty();
      this.otpForm.markAllAsTouched();
      return;
    }
    let data;
    let value = '';
    this.otpValueGroup.filter(input => value += this.otpForm.value[input]);
    data = {
      otp: value,
      otp_id: this.otp_id
    };
    this.apiSer.api(data, 'profile/verify_otp').subscribe((data) => {
      if (data.is_error == 0) {
        this.router.navigate(['business-info']);
      } else {
        this.otpForm.markAsDirty();
        this.otpForm.markAsTouched();
        window.scroll(0, 0);
      }
    });
  }
  get f() {
    return this.otpForm.controls;
  }
}
