<div class="login-left-side h-100 d-flex flex-column justify-content-between p-4">
  <div>
    <img src="assets/images/icons/FM-logo.svg" alt="" class="fm-logo">
  </div>

  <img src="assets/images/background/theme-bg.svg" alt="" class="shield-icon">
  <div>
    <h4 class="fs-46 fw-600 text-white m-0 pb-3 fs-46-sm">
      We are <br>
      Building a <br> Secure Financial Foundation

    </h4>
    <p class="fs-16 fw-400 text-grey m-0">
      Your Financial Success Story Starts Here
    </p>
  </div>
</div>