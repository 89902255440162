<div class="side-menu-wrapper h-100 w-100 overflow-auto hide-scroll-bar d-flex flex-column justify-content-between" id="mySidenav">
 <img src="assets/images/icons/white-cross.svg" alt="" class="close-menu text-white d-xl-none position-absolute top-0 end-0 mt-3 me-3" (click)="closeNavMenu()" style="height: 30px;">
    <div>
      <div class="menu-logo-wrapper p-4 border-bottom">
        <img src="assets\images\icons\FM-logo.svg" alt="">
      </div>
      <ul class="menu-list p-0 pt-3">
        <ng-container *ngFor="let menu of menuList;let i = index">
          <li *ngIf="menu.only_path == 0" class= "decoration-none mx-3 px-3 padding-menu cursor text-white radius-4"  [routerLink]="menu.submenu && menu.submenu.length ? null : menu.path" [ngClass]="activeMenuStates[menu.path] ? 'active' : ''">
            <span class="d-flex w-100 justify-content-between" (click)="toggleMenu(menu)">
             <span class="d-flex align-items-center">
              <img [src]="'assets/images/menu-icons/' + menu.icon + '.png'" alt="" class="me-3">

              <p class="m-0 fs-14 fw-500">
                {{ menu.menu_name }}
              </p>
             </span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="height: 16px;" class="toggle-arrow"
                *ngIf="menu.submenu && menu.submenu.length" [ngClass]="{ 'rotate': menu.isOpen }">
                <path
                  d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                  fill="#fff" />
              </svg>
            </span>
            <ul class="sub-menu ps-2  overflow-hidden position-relative d-flex flex-column justify-content-between" [ngClass]="{'pt-3': menu.isOpen}" [ngStyle]="menu.isOpen ? {'height.px': 37 * menu.submenu.length} : {'height': '0px'}">
              <ng-container *ngFor="let sub_menu of menu.submenu; let i = index">
                <li *ngIf="sub_menu.only_path == 0" class="decoration-none  fs-14 text-white" [routerLink]="sub_menu.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  - {{ sub_menu.menu_name }}
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="p-3 radius-8 mx-3 mb-3 explore-box" >
      <h4 class="fs-14 fw-700 theme-text mb-2">
        EZ Financing
      </h4>
      <p class="fs-16 fw-800 mb-4">
        Lower rates for customers, instant payouts, and secure payments for you.
      </p>
      <div class="btn btn-primary w-100">
        Explore Now
      </div>
    </div>
  </div>